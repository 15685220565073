#header {
    .navigation {
        >li {
            &:hover,
            &.active {
                > a {
                    @apply text-gray-600;
                    @include media ('>=tablet') {
                        @apply text-gray-400 font-medium;
                    }
                }
                .drop {
                    @include media ('>=tablet') {
                        @apply opacity-100;
                    }
                }
            }
        }
        .drop {
            @include animate(max-height opacity visibility);
            li {
                &:hover,
                &.active {
                    a {
                        @apply text-gray-600;
                        @include media ('>=tablet') {
                            @apply text-black font-bold;
                        }
                    }
                }
            }
        }
    }
}

.nav-opener {
    .nav-active & {
        span {
            @apply opacity-0;
        }
        &:before,
        &:after {
            @apply rotate-45 left-0 right-0 top-50pc;
        }
        &:after {
            @apply transform -rotate-45;
        }
    }
    span,
    &:before,
    &:after {
        @apply bg-white absolute top-50pc left-0 right-0 h-3 -mt-2;
        transition: all .3s linear;
    }
    &:before,
    &:after {
        content: '';
        @apply top-2;
    }
    &:after {
        top: rem-calc(16);
    }
}

.nav-active {
    @apply fixed overflow-hidden w-100pc;
}

.menu-drop {
    @include media ('<tablet') {
        @include animate(opacity visibility);
        @apply absolute opacity-0 invisible z-9 inset-x-0 top-0 h-screen overflow-x-hidden overflow-y-auto bg-gray-300 px-20 pt-90 pb-25 w-full;
        .nav-active & {
            @apply opacity-100 visible;
        }
    }
}
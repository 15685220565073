@tailwind base;
@tailwind components;
@tailwind utilities;
ul:not([class]):not([id]) {
  margin: 0;
  padding: 0;
  list-style: none; }

.clearfix:after {
  content: '';
  display: block;
  clear: both; }

.ellipsis {
  white-space: nowrap;
  /* 1 */
  text-overflow: ellipsis;
  /* 2 */
  overflow: hidden; }

html {
  box-sizing: border-box; }

*, *:before, *:after {
  box-sizing: inherit; }

* {
  max-height: 1000000px; }

img {
  max-width: 100%;
  height: auto;
  vertical-align: top; }

.gm-style img {
  max-width: none; }

@font-face {
  font-family: 'VisbyCF';
  src: url("../fonts/VisbyCF-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'VisbyCF';
  src: url("../fonts/VisbyCF-DemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'VisbyCF';
  src: url("../fonts/VisbyCF-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'VisbyCF';
  src: url("../fonts/VisbyCF-Thin.woff") format("woff");
  font-weight: normal;
  font-style: 100; }

@font-face {
  font-family: 'VisbyCF';
  src: url("../fonts/VisbyCF-ThinOblique.woff") format("woff");
  font-weight: normal;
  font-style: 100; }

@font-face {
  font-family: 'VisbyCF';
  src: url("../fonts/VisbyCF-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'VisbyCF Heavy';
  src: url("../fonts/VisbyCF-Heavy.woff") format("woff");
  font-weight: 900;
  font-style: normal; }

@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon.eot?kd8470");
  src: url("../fonts/icomoon.eot?kd8470#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?kd8470") format("truetype"), url("../fonts/icomoon.woff?kd8470") format("woff"), url("../fonts/icomoon.svg?kd8470#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-location:before {
  content: "\e902"; }

.icon-mail:before {
  content: "\e903"; }

.icon-phone:before {
  content: "\e909"; }

.icon-facebook:before {
  content: "\e90a"; }

.icon-twitter:before {
  content: "\e90b"; }

.icon-th-menu:before {
  content: "\e905"; }

.icon-quote-left:before {
  content: "\e904"; }

.icon-home:before {
  content: "\e908"; }

.icon-file-pdf-o:before {
  content: "\e907"; }

.icon-search:before {
  content: "\e906"; }

/* Slick slider styles */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.resize-active * {
  transition: none !important; }

/*
* set default background
*/
body {
  @apply bg-white text-gray-300 font-VisbyCF text-base leading-base; }

#wrapper {
  @apply relative overflow-hidden max-w-inner4 mx-auto shadow-sm; }

#main {
  @apply overflow-hidden; }

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  @apply font-extra; }

.container {
  @apply max-w-custom; }
  .container.container-lg {
    @apply max-w-6xl; }
  .container.contact-container {
    @apply max-w-inner2; }
  .container.design-container {
    @apply max-w-inner3; }
  #footer .container {
    @apply max-w-inner; }
  #header .container {
    @apply max-w-head; }
  .docs-section .container {
    @apply max-w-inner1; }
  .faq .container {
    @apply max-w-3xl; }
  .info-comingsoon .container {
    @apply max-w-inner2; }

/* set styles for default page links */
a {
  transition: color 0.3s ease-in-out;
  @apply no-underline; }

input[type="text"],
input[type="email"],
input[type="tel"],
input[type="search"],
input[type="password"],
input[type="url"],
input[type="date"],
input[type="number"],
textarea {
  transition: border-color 0.3s ease-in-out;
  @apply w-100pc border-0 rounded-none border-b-1 border-innerGray pl-0;
  -webkit-appearance: none !important; }
  input[type="text"]:focus, input[type="text"]:hover,
  input[type="email"]:focus,
  input[type="email"]:hover,
  input[type="tel"]:focus,
  input[type="tel"]:hover,
  input[type="search"]:focus,
  input[type="search"]:hover,
  input[type="password"]:focus,
  input[type="password"]:hover,
  input[type="url"]:focus,
  input[type="url"]:hover,
  input[type="date"]:focus,
  input[type="date"]:hover,
  input[type="number"]:focus,
  input[type="number"]:hover,
  textarea:focus,
  textarea:hover {
    @apply outline-none border-blue; }
  input[type="text"]::-webkit-input-placeholder,
  input[type="email"]::-webkit-input-placeholder,
  input[type="tel"]::-webkit-input-placeholder,
  input[type="search"]::-webkit-input-placeholder,
  input[type="password"]::-webkit-input-placeholder,
  input[type="url"]::-webkit-input-placeholder,
  input[type="date"]::-webkit-input-placeholder,
  input[type="number"]::-webkit-input-placeholder,
  textarea::-webkit-input-placeholder {
    @apply text-xs3 text-gray-400 tracking-2sm; }
  input[type="text"]::-moz-placeholder,
  input[type="email"]::-moz-placeholder,
  input[type="tel"]::-moz-placeholder,
  input[type="search"]::-moz-placeholder,
  input[type="password"]::-moz-placeholder,
  input[type="url"]::-moz-placeholder,
  input[type="date"]::-moz-placeholder,
  input[type="number"]::-moz-placeholder,
  textarea::-moz-placeholder {
    opacity: 1;
    @apply text-xs3 text-gray-400 tracking-2sm; }
  input[type="text"]:-moz-placeholder,
  input[type="email"]:-moz-placeholder,
  input[type="tel"]:-moz-placeholder,
  input[type="search"]:-moz-placeholder,
  input[type="password"]:-moz-placeholder,
  input[type="url"]:-moz-placeholder,
  input[type="date"]:-moz-placeholder,
  input[type="number"]:-moz-placeholder,
  textarea:-moz-placeholder {
    @apply text-xs3 text-gray-400 tracking-2sm; }
  input[type="text"]:-ms-input-placeholder,
  input[type="email"]:-ms-input-placeholder,
  input[type="tel"]:-ms-input-placeholder,
  input[type="search"]:-ms-input-placeholder,
  input[type="password"]:-ms-input-placeholder,
  input[type="url"]:-ms-input-placeholder,
  input[type="date"]:-ms-input-placeholder,
  input[type="number"]:-ms-input-placeholder,
  textarea:-ms-input-placeholder {
    @apply text-xs3 text-gray-400 tracking-2sm; }
  input[type="text"].placeholder,
  input[type="email"].placeholder,
  input[type="tel"].placeholder,
  input[type="search"].placeholder,
  input[type="password"].placeholder,
  input[type="url"].placeholder,
  input[type="date"].placeholder,
  input[type="number"].placeholder,
  textarea.placeholder {
    @apply text-xs3 text-gray-400 tracking-2sm; }

label {
  @apply block text-xs3 tracking-2lg uppercase font-extra -mb-3; }

textarea {
  @apply min-h-152 resize-none border-1 px-10; }
  @media (min-width: 768px) {
    textarea {
      @apply min-h-210; } }

ul:not([class]):not([id]) li {
  @apply relative ml-37 pl-10 pb-13 pb-13; }
  ul:not([class]):not([id]) li:before {
    content: '';
    @apply absolute top-9 left-1 w-3 h-3 rounded-full bg-black; }

.btn {
  transition: background 0.3s ease-in-out;
  @apply text-xs2 leading-6 uppercase text-white text-xs2 min-w-166 bg-gray-400 px-15 py-6 tracking-3sm text-center font-extra inline-block; }
  .btn:hover {
    @apply bg-darkenedBlue; }
  .btn.btn-lg {
    @apply rounded-sm min-w-196 text-lg font-normal py-8 px-15; }

.btn-lg-contact {
  @apply rounded-none min-w-152 font-extra text-xs3 py-8 px-15 uppercase text-gray-50 bg-gray-400 leading-6 tracking-3sm text-center inline-block; }
  .btn-lg-contact:hover {
    @apply bg-darkenedBlue; }

.btn-lg-contact-2 {
  @apply rounded-none min-w-196 font-normal text-base py-8 px-15 uppercase text-gray-50 bg-gray-400 leading-6 tracking-3sm text-center inline-block; }
  @media (min-width: 768px) {
    .btn-lg-contact-2 {
      @apply text-lg; } }
  .btn-lg-contact-2:hover {
    @apply bg-darkenedBlue; }

.contact-text-wrap:before {
  content: '';
  @apply w-1 h-95pc left-5 top-18 bg-gray-20 absolute hidden; }
  @media (min-width: 768px) {
    .contact-text-wrap:before {
      @apply block; } }

.work-slider .slick-slide img {
  display: inline-block; }

.blockquote-slider .slick-list {
  @apply pt-41; }

.blockquote-slider .slick-slide {
  @apply px-20 leading-5; }
  @media (min-width: 768px) {
    .blockquote-slider .slick-slide {
      @apply px-55; } }

@media (min-width: 768px) {
  .blockquote-slider .slick-arrow {
    @apply top-30pc; } }

.blockquote-slider blockquote:before {
  @apply left-20; }
  @media (min-width: 768px) {
    .blockquote-slider blockquote:before {
      @apply -left-8pc; } }

.slick-dots {
  @apply flex flex-wrap items-center justify-center mt-20; }
  .slick-dots li {
    @apply px-4; }
    .slick-dots li.slick-active button {
      @apply bg-white; }
  .slick-dots button {
    transition: background 0.3s ease-in-out;
    @apply text-none w-10 h-10 rounded-full border-1 border-lightGray bg-lightGray outline-none; }

.slick-arrow {
  transition: opacity 0.3s ease-in-out;
  @apply border-0 bg-white text-md w-35 h-35 rounded-full absolute top-20pc outline-none z-3 font-bold flex flex-wrap items-center justify-center p-0; }
  @media (min-width: 768px) {
    .slick-arrow {
      @apply w-42 h-42 top-35pc; } }
  .slick-arrow:hover {
    @apply opacity-60; }
  .slick-arrow.slick-prev {
    @apply left-10; }
  .slick-arrow.slick-next {
    @apply right-10; }

.image-slider .slick-slide {
  @apply px-4 w-auto; }
  @media (min-width: 1200px) {
    .image-slider .slick-slide {
      @apply w-382; } }

.image-slider .slick-arrow {
  @apply top-44pc bg-transparent; }
  .image-slider .slick-arrow.slick-prev {
    @apply left-27; }
  .image-slider .slick-arrow.slick-next {
    @apply right-27; }

@media (min-width: 768px) {
  .features .work-slider .slick-arrow {
    @apply top-34pc; } }

@media (min-width: 1200px) {
  .features .work-slider .slick-arrow.slick-prev {
    @apply left-6pc; } }

@media (min-width: 1200px) {
  .features .work-slider .slick-arrow.slick-next {
    @apply right-6pc; } }

.accordion li.active span:after {
  @apply opacity-0 invisible; }

.accordion li.active span:before {
  @aplly w-8; }

.accordion .opener span:before {
  transition: width 0.3s ease-in-out;
  content: '';
  @apply bg-white absolute transform-x-y w-14 h-2; }

.accordion .opener span:after {
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  content: '';
  @apply bg-white absolute transform-x-y w-2 h-16 visible opacity-100; }

.accordion-list > li.active a {
  @apply text-blue; }

.accordion-list li.active:after {
  @apply opacity-0 invisible; }

.accordion-list li.active:before {
  @apply w-4 left-5; }

.accordion-list li:before {
  transition: width 0.3s ease-in-out, left 0.3s ease-in-out;
  content: '';
  @apply absolute left-4 top-10 w-7 h-1 bg-mediumBlue; }

.accordion-list li:after {
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  content: '';
  @apply absolute left-7 top-7 w-1 h-7 visible opacity-100 bg-mediumBlue visible; }

.accordion-list li .sub-list li:before, .accordion-list li .sub-list li:after {
  @apply hidden; }

.docs-section aside:before {
  content: '';
  @apply absolute top-0 right-0 w-1 h-74pc bg-gray-250 hidden; }
  @media (min-width: 768px) {
    .docs-section aside:before {
      @apply block; } }

.docs-section .page-nav li.active a {
  @apply text-blue; }

.docs-section .page-nav li:last-child:before {
  @apply hidden; }

.docs-section .page-nav li:before {
  content: '';
  @apply absolute top-5 right-0 w-1 h-11 bg-gray-350; }

.search-form input {
  @apply h-26 rounded-xs w-input-search-w pl-14 border-1 border-gray-400 outline-none; }
  @media (min-width: 480px) {
    .search-form input {
      @apply w-217; } }
  .search-form input::-webkit-input-placeholder {
    @apply text-xs2 text-gray-150 tracking-normal; }
  .search-form input::-moz-placeholder {
    opacity: 1;
    @apply text-xs2 text-gray-150 tracking-normal; }
  .search-form input:-moz-placeholder {
    @apply text-xs2 text-gray-150 tracking-normal; }
  .search-form input:-ms-input-placeholder {
    @apply text-xs2 text-gray-150 tracking-normal; }
  .search-form input.placeholder {
    @apply text-xs2 text-gray-150 tracking-normal; }

.search-form button {
  transition: background 0.3s ease-in-out; }

.intro-comingsoon:before {
  content: '';
  @apply absolute top-0 left-0 right-0 bottom-0 bg-brown opacity-50; }

#header .navigation > li:hover > a, #header .navigation > li.active > a {
  @apply text-gray-600; }
  @media (min-width: 768px) {
    #header .navigation > li:hover > a, #header .navigation > li.active > a {
      @apply text-gray-400 font-medium; } }

@media (min-width: 768px) {
  #header .navigation > li:hover .drop, #header .navigation > li.active .drop {
    @apply opacity-100; } }

#header .navigation .drop {
  transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out, visibility 0.3s ease-in-out; }
  #header .navigation .drop li:hover a, #header .navigation .drop li.active a {
    @apply text-gray-600; }
    @media (min-width: 768px) {
      #header .navigation .drop li:hover a, #header .navigation .drop li.active a {
        @apply text-black font-bold; } }

.nav-active .nav-opener span {
  @apply opacity-0; }

.nav-active .nav-opener:before, .nav-active .nav-opener:after {
  @apply rotate-45 left-0 right-0 top-50pc; }

.nav-active .nav-opener:after {
  @apply transform -rotate-45; }

.nav-opener span, .nav-opener:before, .nav-opener:after {
  @apply bg-white absolute top-50pc left-0 right-0 h-3 -mt-2;
  transition: all .3s linear; }

.nav-opener:before, .nav-opener:after {
  content: '';
  @apply top-2; }

.nav-opener:after {
  top: 1rem; }

.nav-active {
  @apply fixed overflow-hidden w-100pc; }

@media (max-width: 767px) {
  .menu-drop {
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
    @apply absolute opacity-0 invisible z-9 inset-x-0 top-0 h-screen overflow-x-hidden overflow-y-auto bg-gray-300 px-20 pt-90 pb-25 w-full; }
    .nav-active .menu-drop {
      @apply opacity-100 visible; } }

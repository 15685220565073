@font-face {
    font-family: 'VisbyCF';
    src: url('../fonts/VisbyCF-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'VisbyCF';
    src: url('../fonts/VisbyCF-DemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'VisbyCF';
    src: url('../fonts/VisbyCF-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'VisbyCF';
    src: url('../fonts/VisbyCF-Thin.woff') format('woff');
    font-weight: normal;
    font-style: 100;
}

@font-face {
    font-family: 'VisbyCF';
    src: url('../fonts/VisbyCF-ThinOblique.woff') format('woff');
    font-weight: normal;
    font-style: 100;
}

@font-face {
    font-family: 'VisbyCF';
    src: url('../fonts/VisbyCF-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'VisbyCF Heavy';
    src: url('../fonts/VisbyCF-Heavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}
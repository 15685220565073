.resize-active {
    * {
        transition: none !important;
    }
}


/*
* set default background
*/

body {
    @apply bg-white text-gray-300 font-VisbyCF text-base leading-base;
}

#wrapper {
    @apply relative overflow-hidden max-w-inner4 mx-auto shadow-sm;
}

#main {
    @apply overflow-hidden;
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    @apply font-extra;
}

.container {
    @apply max-w-custom;
    &.container-lg {
        @apply max-w-6xl;
    }
    &.contact-container {
        @apply max-w-inner2;
    }
    &.design-container {
        @apply max-w-inner3;
    }
    #footer & {
        @apply max-w-inner;
    }
    #header & {
        @apply max-w-head;
    }
    .docs-section & {
        @apply max-w-inner1;
    }
    .faq & {
        @apply max-w-3xl;
    }

    .info-comingsoon & {
        @apply max-w-inner2;
    }
}


/* set styles for default page links */

a {
    @include animate(color);
    @apply no-underline;
}

input[type="text"],
input[type="email"],
input[type="tel"],
input[type="search"],
input[type="password"],
input[type="url"],
input[type="date"],
input[type="number"],
textarea {
    @include animate(border-color);
    @apply w-100pc border-0 rounded-none border-b-1 border-innerGray pl-0;
    -webkit-appearance: none !important;
    &:focus,
    &:hover {
        @apply outline-none border-blue;
    }
    @include placeholder {
        @apply text-xs3 text-gray-400 tracking-2sm;
    }
}

label {
    @apply block text-xs3 tracking-2lg uppercase font-extra -mb-3; // display: block;
}

textarea {
    @apply min-h-152 resize-none border-1 px-10;
    @include media ('>=tablet') {
        @apply min-h-210;
    }
}

ul:not([class]):not([id]) {
    @extend %listreset;
    li {
        @apply relative ml-37 pl-10 pb-13 pb-13;
        &:before {
            content: '';
            @apply absolute top-9 left-1 w-3 h-3 rounded-full bg-black;
        }
    }
}

.btn {
    @include animate(background);
    @apply text-xs2 leading-6 uppercase text-white text-xs2 min-w-166 bg-gray-400 px-15 py-6 tracking-3sm text-center font-extra inline-block;
    &:hover {
        @apply bg-darkenedBlue;
    }
    &.btn-lg {
        @apply rounded-sm min-w-196 text-lg font-normal py-8 px-15;
    }
}

.btn-lg-contact {
    @apply rounded-none min-w-152 font-extra text-xs3 py-8 px-15 uppercase text-gray-50 bg-gray-400 leading-6 tracking-3sm text-center inline-block;
    &:hover {
        @apply bg-darkenedBlue;
    }
}

.btn-lg-contact-2 {
    @apply rounded-none min-w-196 font-normal text-base py-8 px-15 uppercase text-gray-50 bg-gray-400 leading-6 tracking-3sm text-center inline-block;
    @include media ('>=tablet') {
        @apply text-lg;
    }
    &:hover {
        @apply bg-darkenedBlue;
    }
}

.contact-text-wrap {
    &:before {
        content: '';
        @apply w-1 h-95pc left-5 top-18 bg-gray-20 absolute hidden;
        @include media ('>=tablet') {
            @apply block;
        }
    }
}

.work-slider {
    .slick-slide {
        img {
            display: inline-block;
        }
    }
}

.blockquote-slider {
    .slick-list {
        @apply pt-41;
    }
    .slick-slide {
        @apply px-20 leading-5;
        @include media ('>=tablet') {
            @apply px-55;
        }
    }
    .slick-arrow {
        @include media ('>=tablet') {
            @apply top-30pc;
        }
    }
    blockquote {
        &:before {
            @apply left-20;
            @include media ('>=tablet') {
                @apply -left-8pc;
            }
        }
    }
}

.slick-dots {
    @apply flex flex-wrap items-center justify-center mt-20;
    li {
        @apply px-4;
        &.slick-active {
            button {
                @apply bg-white;
            }
        }
    }
    button {
        @include animate(background);
        @apply text-none w-10 h-10 rounded-full border-1 border-lightGray bg-lightGray outline-none;
    }
}

.slick-arrow {
    @include animate(opacity);
    @apply border-0 bg-white text-md w-35 h-35 rounded-full absolute top-20pc outline-none z-3 font-bold flex flex-wrap items-center justify-center p-0;
    @include media ('>=tablet') {
        @apply w-42 h-42 top-35pc;
    }
    &:hover {
        @apply opacity-60;
    }
    &.slick-prev {
        @apply left-10;
    }
    &.slick-next {
        @apply right-10;
    }
}

.image-slider {
    .slick-slide {
        @apply px-4 w-auto;
        @include media ('>=widescreen') {
            @apply w-382;
        }
    }
    .slick-arrow {
        @apply top-44pc bg-transparent;
        &.slick-prev {
            @apply left-27;
        }
        &.slick-next {
            @apply right-27;
        }
    }
}

.features {
    .work-slider {
        .slick-arrow {
            @include media ('>=tablet') {
                @apply top-34pc;
            }
            &.slick-prev {
                @include media ('>=widescreen') {
                    @apply left-6pc;
                }
            }
            &.slick-next {
                @include media ('>=widescreen') {
                    @apply right-6pc;
                }
            }
        }
    }
}

.accordion {
    li {
        &.active {
            span {
                &:after {
                    @apply opacity-0 invisible;
                }
                &:before {
                    @aplly w-8;
                }
            }
        }
    }
    .opener {
        span {
            &:before {
                @include animate(width);
                content: '';
                @apply bg-white absolute transform-x-y w-14 h-2;
            }
            &:after {
                @include animate(opacity visibility);
                content: '';
                @apply bg-white absolute transform-x-y w-2 h-16 visible opacity-100;
            }
        }
    }
}

.accordion-list {
    >li {
        &.active {
            a {
                @apply text-blue;
            }
        }
    }
    li {
        &.active {
            &:after {
                @apply opacity-0 invisible;
            }
            &:before {
                @apply w-4 left-5;
            }
        }
        &:before {
            @include animate(width left);
            content: '';
            @apply absolute left-4 top-10 w-7 h-1 bg-mediumBlue;
        }
        &:after {
            @include animate(opacity visibility);
            content: '';
            @apply absolute left-7 top-7 w-1 h-7 visible opacity-100 bg-mediumBlue visible;
        }
        .sub-list {
            li {
                &:before,
                &:after {
                    @apply hidden;
                }
            }
        }
    }
}

.docs-section {
    aside {
        &:before {
            content: '';
            @apply absolute top-0 right-0 w-1 h-74pc bg-gray-250 hidden;
            @include media ('>=tablet') {
                @apply block;
            }
        }
    }
    .page-nav {
        li {
            &.active {
                a {
                    @apply text-blue;
                }
            }
            &:last-child {
                &:before {
                    @apply hidden;
                }
            }
            &:before {
                content: '';
                @apply absolute top-5 right-0 w-1 h-11 bg-gray-350;
            }
        }
    }
}

.search-form {
    input {
        @apply h-26 rounded-xs w-input-search-w pl-14 border-1 border-gray-400 outline-none;
        @include media ('>=phone') {
            @apply w-217;
        }
        @include placeholder {
            @apply text-xs2 text-gray-150 tracking-normal;
        }
    }
    button {
        @include animate(background);
    }
}

.intro-comingsoon {
    &:before {
        content: '';
        @apply absolute top-0 left-0 right-0 bottom-0 bg-brown opacity-50;
    }
}

@tailwind base;
@tailwind components;
@tailwind utilities;
$breakpoints: ( 'xs-phone': 320px, 'phone': 480px, 'tablet': 768px, 'desktop': 1024px, 'widescreen': 1200px);
// keywords
$media-expressions: ( 'screen': 'screen', 'print': 'print', 'handheld': 'handheld', 'landscape': '(orientation: landscape)', 'portrait': '(orientation: portrait)', 'retina2x': '(-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi)', 'retina3x': '(-webkit-min-device-pixel-ratio: 2.5), (min-resolution: 240dpi)');
// ligatured operators ≥ ≤
@import 'vendors/include-media';
@import 'base/functions';
@import 'base/mixins';
@import 'base/helpers';
@import 'base/reset';
@import 'vendors/fonts';
@import 'vendors/icomoon';
@import 'vendors/slick';
@import 'layout/common';
@import 'layout/header';